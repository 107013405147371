import React, {Component} from 'react';

import strings from '../../strings';

import {de_words_trial} from './assets';
import listen from '../../assets/listen.png';

import './styles.scss';

class Trial extends Component {
	constructor(props) {
		super(props);

		switch (strings.getLanguage()) {
			case 'nn':
				this.assets = de_words_trial;
				break;
			case 'se':
				this.assets = de_words_trial;
				break;
			case 'de':
				this.assets = de_words_trial;
				break;
			case 'no':
			default:
				this.assets = de_words_trial;
				break;
		}

		Object.values(this.assets).forEach((audioEl) => audioEl.load());

		this.state = {
			currentStage: 0,
		};
	}

	componentWillReceiveProps(newProps) {
		if (newProps.currentStep !== this.props.currentStep) {
			this.startNewTask(newProps.currentStep);
		}
	}

	componentDidMount() {
		if (this.button) this.button.click();
		this.initializeAudio();
	}
	componentWillUnmount() {
		this.stopSounds();
		clearTimeout(this.timeout);
	}

	initializeAudio = () => {
		Object.values(this.assets).forEach((audioEl) => audioEl.load());

		this.timeout = setTimeout(
			() => this.startNewTask(this.props.currentStep),
			1000
		);
	};
	startNewTask = (taskNumber) => {
		this.stopSounds();
		this.playSound(taskNumber);
	};

	playSound = (sound) => {
		this.assets[sound].play();
	};

	stopSounds = () => {
		for (const sound of Object.values(this.assets)) {
			sound.pause();
		}
	};

	render() {
		return (
			<div className="WorkingMemory">
				<img className="listen-image" src={listen} alt="" />
				<p>{strings.wsw_test1}</p>
			</div>
		);
	}
}

export default Trial;
