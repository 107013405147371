import React, {Component} from 'react';
import strings from '../../strings';
const __DEBUG__ = false;

class StopWatch extends Component {
	constructor(props) {
		super(props);

		this.state = {
			startedAt: null,
			timeUsed: 0,
			timeUsedLastPause: 0,
			isCounting: false,
			autoStart: Boolean(props.autoStart),
			resetOnStop: Boolean(props.resetOnStop),
			controls: Boolean(props.controls),
			showTime: Boolean(!props.hidden),
		};
	}

	componentDidMount() {
		if (this.state.autoStart) {
			this.start();
		}
	}

	componentWillUnmount() {
		clearTimeout(this.timer);
	}

	tick = () => {
		const {isCounting, timeUsedLastPause, startedAt} = this.state;
		this.onTick();
		const now = new Date();
		const adjustedTimeUsed = (now.valueOf() - startedAt.valueOf()) / 1000;

		this.setState({
			timeUsed: adjustedTimeUsed + timeUsedLastPause,
		});

		if (__DEBUG__) console.log('tick', adjustedTimeUsed);

		if (isCounting) {
			requestAnimationFrame(this.tick);
		}
	};

	start = () => {
		if (__DEBUG__) console.log('start stopwatch');
		this.setState(
			{
				isCounting: true,
				startedAt: new Date(),
			},
			() => {
				this.tick();
				this.onStart();
			}
		);
	};

	pause = () => {
		if (__DEBUG__) console.log('pause stopwatch');
		clearTimeout(this.timer);
		this.setState({
			isCounting: false,
			timeUsedLastPause: this.state.timeUsed,
		});

		this.onPause();
	};

	reset = () => {
		if (__DEBUG__) console.log('reset stopwatch');
		this.onReset();
		this.setState({
			timeUsed: 0,
		});
	};

	stop = () => {
		if (__DEBUG__) console.log('stop stopwatch');
		clearTimeout(this.timer);
		this.onStop();

		this.setState({
			isCounting: false,
		});

		if (this.state.resetOnStop) {
			this.reset();
		}
	};

	onStart = () => {
		if (__DEBUG__) console.log('onStart');
		if (this.props.onStart) this.props.onStart();
	};
	onTick = () => {
		if (__DEBUG__) console.log('onTick');
		if (this.props.onTick) this.props.onTick(this.getTimeUsed());
	};
	onPause = () => {
		if (__DEBUG__) console.log('onPause');
		if (this.props.onPause) this.props.onPause();
	};
	onReset = () => {
		if (__DEBUG__) console.log('onReset');
		if (this.props.onReset) this.props.onReset();
	};
	onStop = () => {
		if (__DEBUG__) console.log('onStop');
		if (this.props.onStop) this.props.onStop();
	};
	onEnd = () => {
		if (__DEBUG__) console.log('onEnd');
		if (this.props.onEnd) this.props.onEnd();
	};

	getTimeUsed = () => {
		return this.props.integer
			? Math.floor(this.state.timeUsed)
			: this.state.timeUsed;
	};

	render() {
		const {controls, showTime} = this.state;

		return (
			<div className="StopWatch">
				{showTime && (
					<span>
						{strings.formatString(
							strings.timeUsed,
							this.getTimeUsed()
						)}
					</span>
				)}
				{controls && (
					<div className="Controls">
						<button onClick={this.start}>{strings.start}</button>
						<button onClick={this.pause}>{strings.pause}</button>
						<button onClick={this.stop}>{strings.stop}</button>
						<button onClick={this.reset}>{strings.reset}</button>
					</div>
				)}
			</div>
		);
	}
}

export default StopWatch;
