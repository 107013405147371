import LocalizedStrings from 'react-localization';

import no from './languages/no';
import nn from './languages/nn';
import se from './languages/se';
import de from './languages/de';

let strings = new LocalizedStrings({
	no,
	nn,
	se,
	de,
});

export default strings;
