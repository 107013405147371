import {getTestsForClass, getTestIDFromName} from '../utils/testUtils';
import strings from '../strings';

export const createResults = (
	roomConfig,
	answers,
	vars = null,
	timers = null,
	testKeyType
) => {
	const reducedTimers = {};
	Object.entries(timers || roomConfig.times)
		.filter(([key]) => key.startsWith(roomConfig.currentTest))
		.forEach(([key, value]) => {
			const type = key.split(' ').pop();
			const step = key.split(' ')[2];
			if (!reducedTimers[step]) reducedTimers[step] = {};
			reducedTimers[step][type] = value;
		});

	const classTests = getTestsForClass(
		roomConfig.ageGroup,
		testKeyType,
		strings.getLanguage()
	);

	const isLastTest =
		roomConfig.currentTest === Object.keys(classTests).length - 1; //-1 cause TestComplete is a thing.

	const results = JSON.stringify({
		answers: answers,
		vars,
		timers: reducedTimers,
		candidateId: roomConfig.candidateId,
		ageGroup: roomConfig.ageGroup,
		testKey: roomConfig.key,
		language: roomConfig.language,
		testComplete: isLastTest,
	});
	const testId = getTestIDFromName(
		roomConfig.currentTest,
		roomConfig.ageGroup,
		testKeyType,
		strings.getLanguage()
	);
	return {testId, results};
};
