import React, {Component} from 'react';

import strings from '../../strings';

import listen from '../../assets/listen.png';

import './styles.scss';

import {no_test, nn_test, se_test, de_test} from './assets';

class Test extends Component {
	constructor(props) {
		super(props);
		switch (strings.getLanguage()) {
			case 'nn':
				this.assets = nn_test;
				break;
			case 'no':
				this.assets = no_test;
				break;
			case 'se':
				this.assets = se_test;
				break;
			case 'de':
				this.assets = de_test;
				break;
			default:
				this.assets = no_test;
				break;
		}
	}

	componentWillReceiveProps(newProps) {
		if (newProps.currentStep !== this.props.currentStep) {
			this.startNewTask(newProps.currentStep);
		}
	}

	componentDidMount() {
		if (this.button) this.button.click();
		this.initializeAudio();
	}
	componentWillUnmount() {
		clearTimeout(this.numbersTimer);
	}

	initializeAudio = () => {
		//Object.values(this.assets).forEach(audioEl => audioEl.load());

		this.timeout = setTimeout(
			() => this.startNewTask(this.props.currentStep),
			1000
		);
	};
	startNewTask = (taskNumber) => {
		clearTimeout(this.numbersTimer);
		this.playNumbers(['sound' + (Number(taskNumber) + 1)]);
	};

	playSound = (sound, callback) => {
		if (callback) {
			console.log('Starting timer');
			const myCallback = (e) => {
				callback(e);
				//this.assets[sound].removeEventListener('ended', myCallback);
			};
			this.assets[sound].on('end', myCallback);
		}
		this.assets[sound].play();
	};

	playNumbers = (soundArray) => {
		if (!soundArray.length) return;

		this.playSound(soundArray[0]);
		this.numbersTimer = setTimeout(() => {
			const newArr = soundArray.slice(1);
			this.playNumbers(newArr);
			if (!newArr.length) this.props.startTimer();
		}, 1000);
	};

	render() {
		return (
			<div className="Spoonerism">
				<img className="listen-image" src={listen} alt="" />
				<p>{strings.sp_instruction}</p>
			</div>
		);
	}
}

export default Test;
