import React, {Fragment} from 'react';

import {getPhonemeIsolation} from '../../constants/tests.js';

import AdminTestPanel from '../../components/AdminTestPanel';
import strings from '../../strings';
import AdminReadyButton from '../../components/AdminReadyButton';

const options = {
	enableHotkeys: false,
	enableTimers: false,
};

class PhonemeIsolationAdmin extends AdminTestPanel {
	constructor(props) {
		super(props, options);
	}

	getTestName = () => {
		return strings.pi_testname;
	};

	renderIntro = () => {
		return (
			<div>
				<p>
					{strings.pi_admin1}
					<br />
					{strings.pi_admin2}
				</p>

				<AdminReadyButton
					remoteIsReady={this.props.candidateReady}
					onClick={this.props.nextStage}
				/>
			</div>
		);
	};

	renderTest = () => {
		const {currentStep} = this.state;
		const steps = getPhonemeIsolation(
			this.props.testKeyType,
			this.props.ageGroup
		).getSteps();
		return (
			<div>
				<p>{strings.candidateAnswersSelf}</p>
				{currentStep < steps.length && (
					<Fragment>
						<p>
							{strings.formatString(
								strings.pi_admin4,
								steps.length
							)}
						</p>
						<p>
							{strings.formatString(
								strings.taskNumOf,
								currentStep,
								steps.length
							)}
						</p>
					</Fragment>
				)}
				{currentStep >= steps.length && (
					<AdminReadyButton
						remoteIsReady={this.props.candidateReady}
						onClick={this.props.nextStage}
					/>
				)}
			</div>
		);
	};

	renderWaiting = () => {
		return (
			<div>
				<p>
					{strings.formatString(
						strings.testDone,
						strings.pi_testname
					)}
				</p>
				<button onClick={this.props.nextStage}>
					{strings.nextTest}
				</button>
			</div>
		);
	};
}

export default PhonemeIsolationAdmin;
