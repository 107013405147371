import React, {Component} from 'react';

import IntroNumbers from './IntroNumbers';
import TestNumbers from './TestNumbers';
import TrialNumbers from './TrialNumbers';
import TrialWords from './TrialWords';
import IntroWords from './IntroWords';
import TestWords from './TestWords';
import Pause from '../../components/Pause';
import strings from '../../strings';

const subStages = [
	IntroNumbers,
	TrialNumbers,
	Pause,
	TestNumbers,
	Pause,
	IntroWords,
	TrialWords,
	Pause,
	TestWords,
	Pause,
];

const testStages = [4,9]
class WorkingMemoryWords extends Component {
	constructor(props) {
		super(props);

		this.state = {
			currentStage: 0,
			currentStep: 0,
		};
	}

	componentWillReceiveProps(newProps) {
		const newState = {};
		let flag = false;
		if (newProps.currentStep !== this.props.currentStep) {
			newState.currentStep = newProps.currentStep;
			flag = true;
		}
		if (newProps.currentStage !== this.props.currentStage) {
			newState.currentStage = newProps.currentStage;
			flag = true;
		}
		if (flag) {
			this.setState(newState);
		}
	}

	render() {
		const {currentStage} = this.state;

		const CurrentSubStage = subStages[currentStage];

		return (
			<div className="WorkingMemory">
				<h1 className="lit-page-title">{strings.ws_testname}</h1>
				<CurrentSubStage
					currentStep={this.state.currentStep}
					nextStep={this.props.nextStep}
					setReady={this.props.setReady}
					startTimer={this.props.startTimer}
					endTimer={this.props.endTimer}
					testName={
						currentStage < 6
							? strings.wsn_testname
							: strings.wsw_testname
					}
					pauseSound={
						strings.getLanguage() === 'de'
							? !testStages.includes(currentStage)
								? 'trial'
								: 'test'
							: 'test'
					}

					//TODO:: change this to according to the type of working memory w/n. Perhaps make use of the currentStage.length
				/>
			</div>
		);
	}
}

export default WorkingMemoryWords;
