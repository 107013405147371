import React from 'react';
import {connect} from 'react-redux';
import './styles.scss';
//import Logo from 'assets/logo-white.png';

import {BANK_ID, BANK_ID_MOBILE} from '../../constants';

import {initAuthAction} from '../../actions/auth';
import strings from '../../strings';

class Login extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			isInit: false,
		};
	}

	componentDidMount() {
		//this.props.initAuth(BANK_ID, this.props.testKey);
	}

	componentWillReceiveProps(newProps) {
		if (newProps.isAuthenticated && !this.props.isAuthenticated) {
			this.props.onLogin(this.props.testKey);
		}
	}

	render() {
		const {error, iframeUrl, initAuth} = this.props;
		const {isInit} = this.state;
		const showIframe = isInit && iframeUrl;

		return (
			<div>
				<div className="lit-view">
					<header className="lit-header" />
					<div className="lit-view__content">
						<div className="lit-login">
							{!isInit && (
								<div>
									<div className="lit-login__wrapper bg-white">
										<h1 className="lit-login__header">
											{strings.logIn}
										</h1>
										<p className="lit-login__description">
											{strings.loginWithBankId}
										</p>
										<p className="lit-login__description">
											{strings.thisIsForTestAdmins}
										</p>
										<div className="lit-login__action-wrapper">
											<button
												className="lit-btn bg-primary"
												onClick={() => {
													initAuth(
														BANK_ID,
														this.props.testKey
													);
													this.setState({
														isInit: true,
													});
												}}
											>
												{strings.bankId}
											</button>
											<button
												className="lit-btn bg-primary bg-border"
												style={{marginLeft: 10}}
												onClick={() => {
													initAuth(
														BANK_ID_MOBILE,
														this.props.testKey
													);
													this.setState({
														isInit: true,
													});
												}}
											>
												{strings.bankIdMobile}
											</button>
										</div>
									</div>
								</div>
							)}
							{error && (
								<p>
									<strong>{JSON.stringify(error)}</strong>
								</p>
							)}
							{showIframe && (
								<div className="bank-id-iframe-wrapper">
									<iframe
										title="authframe"
										frameBorder="0"
										id="authFrame"
										scrolling="no"
										src={iframeUrl}
										style={{
											minWidth: '430px',
											width: '100%',
											minHeight: '200px',
										}}
									/>
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	const {error, iframeUrl, isInit, isAuthenticated} = state.auth;

	return {
		error,
		iframeUrl,
		isInit,
		isAuthenticated,
	};
};

const mapDispatchToProps = {
	initAuth: initAuthAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
