import {Howl} from 'howler';

import no_introSound from '../../assets/sounds/no/intros/pd-intro.mp3';
import no_gris from '../../assets/sounds/no/phonemeDeletion/gris.mp3';
import no_ris from '../../assets/sounds/no/phonemeDeletion/gris-g.mp3';
import no_foere from '../../assets/sounds/no/phonemeDeletion/foere.mp3';
import no_oere from '../../assets/sounds/no/phonemeDeletion/foere-f.mp3';
import no_sel from '../../assets/sounds/no/phonemeDeletion/sel.mp3';
import no_se from '../../assets/sounds/no/phonemeDeletion/sel-l.mp3';
import no_gaas from '../../assets/sounds/no/phonemeDeletion/gaas-g.mp3';
import no_maal from '../../assets/sounds/no/phonemeDeletion/maal-m.mp3';
import no_broed from '../../assets/sounds/no/phonemeDeletion/broed-b.mp3';
import no_klaer from '../../assets/sounds/no/phonemeDeletion/klaer-k.mp3';
import no_brev from '../../assets/sounds/no/phonemeDeletion/brev-b.mp3';
import no_trygg from '../../assets/sounds/no/phonemeDeletion/trygg-t.mp3';
import no_drap from '../../assets/sounds/no/phonemeDeletion/drap-d.mp3';
import no_skyll from '../../assets/sounds/no/phonemeDeletion/skyll-l.mp3';
import no_laas from '../../assets/sounds/no/phonemeDeletion/laas-s.mp3';
import no_bro from '../../assets/sounds/no/phonemeDeletion/bro-r.mp3';
import no_klatre from '../../assets/sounds/no/phonemeDeletion/klatre-t.mp3';
import no_malt from '../../assets/sounds/no/phonemeDeletion/malt-l.mp3';
import no_bry from '../../assets/sounds/no/phonemeDeletion/bry-r.mp3';
import no_lekse from '../../assets/sounds/no/phonemeDeletion/lekse-k.mp3';
import no_sport from '../../assets/sounds/no/phonemeDeletion/sport-p.mp3';
import no_loff from '../../assets/sounds/no/phonemeDeletion/loff-f.mp3';

import nn_introSound from '../../assets/sounds/nn/intros/pd-intro.mp3';
import nn_gris from '../../assets/sounds/nn/phonemeDeletion/gris.mp3';
import nn_ris from '../../assets/sounds/nn/phonemeDeletion/gris-g.mp3';
import nn_foere from '../../assets/sounds/nn/phonemeDeletion/foere.mp3';
import nn_oere from '../../assets/sounds/nn/phonemeDeletion/foere-f.mp3';
import nn_sel from '../../assets/sounds/nn/phonemeDeletion/sel.mp3';
import nn_se from '../../assets/sounds/nn/phonemeDeletion/sel-l.mp3';
import nn_gaas from '../../assets/sounds/nn/phonemeDeletion/gaas-g.mp3';
import nn_maal from '../../assets/sounds/nn/phonemeDeletion/maal-m.mp3';
import nn_broed from '../../assets/sounds/nn/phonemeDeletion/broed-b.mp3';
import nn_klede from '../../assets/sounds/nn/phonemeDeletion/klede-k.mp3';
import nn_brev from '../../assets/sounds/nn/phonemeDeletion/brev-b.mp3';
import nn_trygg from '../../assets/sounds/nn/phonemeDeletion/trygg-t.mp3';
import nn_drap from '../../assets/sounds/nn/phonemeDeletion/drap-d.mp3';
import nn_skyl from '../../assets/sounds/nn/phonemeDeletion/skyl-l.mp3';
import nn_laas from '../../assets/sounds/nn/phonemeDeletion/laas-s.mp3';
import nn_bro from '../../assets/sounds/nn/phonemeDeletion/bru-r.mp3';
import nn_klatre from '../../assets/sounds/nn/phonemeDeletion/klatre-t.mp3';
import nn_malt from '../../assets/sounds/nn/phonemeDeletion/malt-l.mp3';
import nn_bry from '../../assets/sounds/nn/phonemeDeletion/bry-r.mp3';
import nn_lekse from '../../assets/sounds/nn/phonemeDeletion/lekse-k.mp3';
import nn_sport from '../../assets/sounds/nn/phonemeDeletion/sport-p.mp3';
import nn_loff from '../../assets/sounds/nn/phonemeDeletion/loff-f.mp3';

export const no_intro = {
	intro: no_introSound,
	task1: no_gris,
	task1a: no_ris,
	task2: no_foere,
	task2a: no_oere,
	task3: no_sel,
	task3a: no_se,
};

export const nn_intro = {
	intro: nn_introSound,
	task1: nn_gris,
	task1a: nn_ris,
	task2: nn_foere,
	task2a: nn_oere,
	task3: nn_sel,
	task3a: nn_se,
};

export const no_test = [
	new Howl({src: no_gaas}),
	new Howl({src: no_maal}),
	new Howl({src: no_broed}),
	new Howl({src: no_klaer}),
	new Howl({src: no_brev}),
	new Howl({src: no_trygg}),
	new Howl({src: no_drap}),
	new Howl({src: no_skyll}),
	new Howl({src: no_laas}),
	new Howl({src: no_bro}),
	new Howl({src: no_klatre}),
	new Howl({src: no_malt}),
	new Howl({src: no_bry}),
	new Howl({src: no_lekse}),
	new Howl({src: no_sport}),
	new Howl({src: no_loff}),
];

export const nn_test = [
	new Howl({src: nn_gaas}),
	new Howl({src: nn_maal}),
	new Howl({src: nn_broed}),
	new Howl({src: nn_klede}),
	new Howl({src: nn_brev}),
	new Howl({src: nn_trygg}),
	new Howl({src: nn_drap}),
	new Howl({src: nn_skyl}),
	new Howl({src: nn_laas}),
	new Howl({src: nn_bro}),
	new Howl({src: nn_klatre}),
	new Howl({src: nn_malt}),
	new Howl({src: nn_bry}),
	new Howl({src: nn_lekse}),
	new Howl({src: nn_sport}),
	new Howl({src: nn_loff}),
];
