import {ENDPOINT_URL} from '../constants';

const headers = new Headers();
headers.append('Content-Type', 'application/json');

export const initAuth = (method, key) => {
	const payload = {
		method,
		key,
		origin: 'childTest',
	};

	const body = JSON.stringify(payload);

	return fetch(`${ENDPOINT_URL}/initAuthWithKey`, {
		method: 'POST',
		mode: 'cors',
		credentials: 'include',
		SameSite: 'None',
		headers,
		body,
	}).then((response) => {
		if (response.ok) {
			//window.addEventListener('message', authCallback);
			return response.json();
		}
		let errorMessage;
		if (response.status === 401) {
			errorMessage = 'Ikke gyldig testkode';
		} else if (response.statusText) {
			errorMessage = response.statusText;
		} else {
			errorMessage = 'En ukjent feil har skjedd';
		}
		return Promise.reject(errorMessage);
	});
};
