import React from 'react';

import AudioPlayer from '../../components/AudioPlayer';

import {
	audioFiles as audioFilesNo,
	normingAudioFiles as normingAudioFilesNo,
} from './languages/no-audioFiles';

import {
	audioFiles as audioFilesNn,
	normingAudioFiles as normingAudioFilesNn,
} from './languages/nn-audioFiles';

import {getWordDictation} from '../../constants/tests';
import strings from '../../strings';

/* const NUMBER_OF_TASKS = getWordDictation(this.props.testKeyType).getSteps()
	.length; */

const maxAudioPlayCount = 2;

class Test extends React.Component {
	constructor(props) {
		super(props);

		switch (strings.getLanguage()) {
			case 'nn':
				this.audioFiles =
					this.props.testKeyType === 'youth-followup' ||
					this.props.testKeyType === 'adult-followup'
						? normingAudioFilesNn
						: audioFilesNn;
				break;
			case 'no':
				this.audioFiles =
					this.props.testKeyType === 'youth-followup' ||
					this.props.testKeyType === 'adult-followup'
						? normingAudioFilesNo
						: audioFilesNo;
				break;
			default:
				this.audioFiles =
					this.props.testKeyType === 'youth-followup' ||
					this.props.testKeyType === 'adult-followup'
						? normingAudioFilesNo
						: audioFilesNo;
				break;
		}

		const initialresults = {};
		const NUMBER_OF_TASKS = getWordDictation(
			this.props.testKeyType
		).getSteps().length;
		for (let i = 1; i <= NUMBER_OF_TASKS; i++) {
			initialresults[i] = '';
		}

		this.state = {
			results: initialresults,
			disabledAssignments: {},
			audioPlayCounts: {},
		};

		this.setResults = this.setResults.bind(this);
	}

	setResults = (id, value) => {
		const {results} = this.state;
		this.setState({
			results: Object.assign({}, results, {
				[id]: value,
			}),
		});
	};

	getDisabledAssignments = () => {
		const NUMBER_OF_TASKS = getWordDictation(
			this.props.testKeyType
		).getSteps().length;
		const {results} = this.state;

		const disabledAssignments = {};
		for (let i = 1; i <= NUMBER_OF_TASKS; i++) {
			if (results[i]) {
				disabledAssignments[i] = true;
			}
		}
		return disabledAssignments;
	};

	render() {
		const {refs} = this.props;

		const {audioPlayCounts, disabledAssignments, results} = this.state;

		const allResultsHasValues = Object.keys(results).every(
			(key) => results[key]
		);
		const isNextButtonVisible = allResultsHasValues;
		return (
			<div className="lit-wrapper--thin lit-test lit-diktat">
				{Object.keys(results).map((key) => {
					const assignmentId = key;
					const audioFile = this.audioFiles[assignmentId - 1];
					const assignmentAudioPlayCount =
						audioPlayCounts[assignmentId] || 0;
					const isDisabled = disabledAssignments[assignmentId];
					const isAudioDisabled =
						isDisabled ||
						assignmentAudioPlayCount === maxAudioPlayCount;

					let audioPlayerRef;
					return (
						<div
							className={`lit-input lit-revealed ${
								isDisabled ? 'lit-input--disabled' : ''
							}`}
							key={assignmentId}
						>
							<AudioPlayer
								disabled={isAudioDisabled}
								id={assignmentId}
								onStart={() => {
									this.setState({
										audioPlayCounts: Object.assign(
											{},
											audioPlayCounts,
											{
												[assignmentId]:
													assignmentAudioPlayCount +
													1,
											}
										),
									});
								}}
								ref={(ref) => {
									audioPlayerRef = ref;
								}}
								refs={refs}
								src={audioFile}
							/>
							<input
								className="lit-input__field"
								disabled={isDisabled}
								onChange={(ev) => {
									if (isDisabled) {
										return;
									}
									const value = ev.target.value;
									this.setResults(assignmentId, value);
								}}
								onFocus={() => {
									const disabledAssignments = this.getDisabledAssignments();
									disabledAssignments[assignmentId] = false;

									if (assignmentAudioPlayCount === 0) {
										audioPlayerRef.handleStartPlayer();
									}

									this.setState({
										disabledAssignments,
									});
								}}
								placeholder={strings.wd_writeHere}
								spellCheck={false}
								autoComplete="off"
								autoCorrect="off"
								autoCapitalize="off"
								type="text"
							/>
						</div>
					);
				})}
				{isNextButtonVisible && (
					<button
						className="lit-btn lit bg-primary"
						onClick={() =>
							this.props.submitWordDictation(this.state.results)
						}
					>
						{strings.done}
					</button>
				)}
			</div>
		);
	}
}

export default Test;
