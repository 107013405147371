import React, {Component} from 'react';

import ReadyButton from '../../components/ReadyButton';
import AudioPlayer from '../../components/AudioPlayer';

import {no, nn, se, de} from './assets';

import strings from '../../strings';
import {getTestLanguage} from '../../constants/tests';

const ranIntroItemImages = getTestLanguage().ranIntroItemImages;
const ranIntroImages = getTestLanguage().ranIntroImages;
const colors = getTestLanguage().rnColors;

class Intro extends Component {
	constructor(props) {
		super(props);

		switch (strings.getLanguage()) {
			case 'nn':
				this.assets = nn;
				break;
			case 'se':
				this.assets = se;
				break;
			case 'de':
				this.assets = de;
				break;
			case 'no':
			default:
				this.assets = no;
				break;
		}

		this.state = {
			stepData: {},
			hasCorrectWordsGiven: false,
			step: 0,
		};
	}

	componentWillReceiveProps(newProps) {
		if (this.props.currentStep !== newProps.currentStep) {
			this.setState({step: newProps.currentStep});
		}
		if (this.props.stepData !== newProps.stepData) {
			const hasCorrectWordsGiven =
				newProps.stepData.hasOwnProperty('correctWordsGiven') &&
				newProps.stepData.correctWordsGiven;

			if (!this.state.hasCorrectWordsGiven && hasCorrectWordsGiven) {
				this.setState({
					hasCorrectWordsGiven,
				});
				this.playSecondClip();
			}
		}
	}

	playSecondClip = () => {
		if (this.audio2) this.audio2.handleStartPlayer();
	};

	render() {
		const step = this.state.step;
		return (
			<div className="RapidNamingIntro">
				{step === 0 && (
					<p>
						<AudioPlayer autoplay inline src={this.assets.intro1} />
						{strings.rn_intro1}
					</p>
				)}
				{step === 1 && (
					<>
						<p>
							<AudioPlayer
								autoplay
								inline
								src={this.assets.intro2}
							/>
							{strings.rn_intro2}
						</p>
						<div className="items">
							{colors.map((color) => (
								<div className={`circle ${color}`} />
							))}
						</div>
					</>
				)}
				{step === 2 && (
					<>
						<p>
							<AudioPlayer
								autoplay
								inline
								src={this.assets.intro3}
							/>
							{strings.intro3}
						</p>
						<div className="items">
							{ranIntroItemImages.map((item) => (
								<img className="image" src={item} alt="" />
							))}
						</div>
					</>
				)}
				{step === 3 && (
					<>
						<p>
							<AudioPlayer
								autoplay
								inline
								src={this.assets.intro4}
							/>
							{strings.intro4}
						</p>
						<div className="items">
							{ranIntroImages.map((item) => (
								<img className="image" src={item} alt="" />
							))}
						</div>
					</>
				)}
				{step === 4 && (
					<>
						<p>
							<AudioPlayer
								ref={(node) => (this.audio2 = node)}
								inline
								autoplay
								src={this.assets.intro5}
							/>
							{strings.rn_intro5}
						</p>
						<ReadyButton onReadyChange={this.props.setReady} />
					</>
				)}
			</div>
		);
	}
}

export default Intro;
