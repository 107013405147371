import React, {Component} from 'react';
import './styles.scss';

import strings from '../../strings';
import {Howl} from 'howler';
import nosound from '../../assets/nosound.mp3';

class ReadyButton extends Component {
	constructor(props) {
		super(props);

		this.state = {isReady: false};
	}

	toggleReady = () => {
		const isReady = !this.state.isReady;
		this.setState({
			isReady,
		});

		//Tell parent about this state change if callback is set.
		if (this.props.onReadyChange) this.props.onReadyChange(isReady);
	};

	onClick = () => {
		if (this.props.onClick) this.props.onClick();
		this.toggleReady();

		new Howl({src: nosound}).play();
	};

	render() {
		const {isReady} = this.state;

		let currentText = strings.youAreReady;
		if (!isReady) currentText = strings.ready;

		return (
			<div
				className={`ReadyButton${isReady ? ' ready' : ''}`}
				onClick={this.onClick}
			>
				{currentText}
			</div>
		);
	}
}

export default ReadyButton;
