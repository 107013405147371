import React, {Component} from 'react';

import './styles.scss';
import {getRapidNaming, getTestLanguage} from '../../constants/tests.js';

const images = getTestLanguage().rnImages;

const getImage = (color, item) => {
	if (images[color][item]) return images[color][item];
	console.error('Item ' + color + item + ' not found');
	return null;
};

class Test extends Component {
	constructor(props) {
		super(props);

		this.state = {
			currentStep: 0,
		};
	}

	componentWillReceiveProps(newProps) {
		if (newProps.currentStep !== this.props.currentStep) {
			this.startNewTask(newProps.currentStep);
		}
	}

	componentDidMount() {}

	startNewTask = (taskNumber) => {
		this.setState({
			currentStep: taskNumber,
		});
	};

	render() {
		const {currentStep} = this.state;
		const steps = getRapidNaming(this.props.ageGroup).getAnswers();
		const itemsPerRow = steps[0].length;
		const currentRow = Math.min(
			Math.floor(currentStep / itemsPerRow),
			steps.length - 1
		);
		const currentItem = Math.floor(currentStep % itemsPerRow);

		const row = steps[currentRow] || null;
		const previousRow = steps[currentRow - 1] || null;
		const nextRow = steps[currentRow + 1] || null;

		return (
			<div className="ScrollingTest">
				<div className="row previous">
					{previousRow &&
						previousRow.map((item, index) => (
							<p key={index} className={`item`}>
								<img
									src={getImage(item.color, item.item)}
									alt={`A ${item.color} ${item.item}`}
								/>
								{(currentRow - 1) * itemsPerRow + index + 1}
							</p>
						))}
				</div>
				<div className="row">
					{row &&
						row.map((item, index) => (
							<p
								key={index}
								className={`item${
									currentItem === index ? ' active' : ''
								}`}
							>
								<img
									src={getImage(item.color, item.item)}
									alt={`A ${item.color} ${item.item}`}
								/>
								{currentRow * itemsPerRow + index + 1}
							</p>
						))}
				</div>
				<div className="row next">
					{nextRow &&
						nextRow.map((item, index) => (
							<p key={index} className={`item`}>
								<img
									src={getImage(item.color, item.item)}
									alt={`A ${item.color} ${item.item}`}
								/>
								{(currentRow + 1) * itemsPerRow + index + 1}
							</p>
						))}
				</div>
			</div>
		);
	}
}

export default Test;
