import React, {Component} from 'react';

import wm_number from '../../assets/sounds/de/pauses/FOL018.wav';
import wm_trial_sound from '../../assets/sounds/de/pauses/SCR007.wav';
import wm_words from '../../assets/sounds/de/pauses/FOL035.wav';
import ran from '../../assets/sounds/de/pauses/FOL045.wav';
import Onemint from '../../assets/sounds/de/pauses/FOL047.wav';
import spoonerism from '../../assets/sounds/de/pauses/FOL072.wav';

import AudioPlayer from '../../components/AudioPlayer';
import strings from '../../strings';

class Pause extends Component {
	constructor(props) {
		super(props);

		this.wm_number_sound = wm_number;
		this.wm_words_sound = wm_words;
		this.ran_sound = ran;
		this.Onemint_sound = Onemint;
		this.spoonerism_sound = spoonerism;

		this.state = {
			testName: this.props.testName ? this.props.testName : '',
			displayBtn: true,
		};
	}

	returnRelevantSound(testName) {
		switch (testName) {
			case strings.wsn_testname:
				if (this.props.pauseSound === 'trial') {
					return wm_trial_sound;
				} else return this.wm_number_sound;
				break;

			case strings.wsw_testname:
				if (this.props.pauseSound === 'trial') {
					return wm_trial_sound;
				} else return this.wm_words_sound;
				break;

			case strings.rn_testname:
				return this.ran_sound;
				break;

			case strings.om_testname:
				return this.Onemint_sound;
				break;

			case strings.sp_testname:
				return this.spoonerism_sound;
				break;
		}
	}

	render() {
		const {testName} = this.state;
		return (
			<div>
				<h2>
					{testName && strings.getLanguage() === 'de' && (
						<AudioPlayer
							autoplay
							inline
							src={this.returnRelevantSound(testName)}
						/>
					)}
					{this.props.pauseSound !== 'trial'
						? strings.formatString(
								strings.pauseBeforeNextTest,
								testName
						  )
						: strings.trialIsNowOver}
				</h2>
				{this.props.pauseSound === 'trial' && this.state.displayBtn && (
					<div>
						<button
							onClick={(e) => {
								this.setState({
									displayBtn: false,
								});
							}}
						>
							{strings.start}
						</button>
					</div>
				)}
			</div>
		);
	}
}

export default Pause;
