import grabamse from '../../../assets/images/rapidNaming/grabamse.png';
import gragenser from '../../../assets/images/rapidNaming/gragenser.png';
import graeple from '../../../assets/images/rapidNaming/graeple.png';
import gratommel from '../../../assets/images/rapidNaming/gratommel.png';
import grasykkel from '../../../assets/images/rapidNaming/grasykkel.png';

import blabamse from '../../../assets/images/rapidNaming/blabamse.png';
import blagenser from '../../../assets/images/rapidNaming/blagenser.png';
import blaeple from '../../../assets/images/rapidNaming/blaeple.png';
import blatommel from '../../../assets/images/rapidNaming/blatommel.png';
import blasykkel from '../../../assets/images/rapidNaming/blasykkel.png';

import oransjebamse from '../../../assets/images/rapidNaming/oransjebamse.png';
import oransjegenser from '../../../assets/images/rapidNaming/oransjegenser.png';
import oransjeeple from '../../../assets/images/rapidNaming/oransjeeple.png';
import oransjetommel from '../../../assets/images/rapidNaming/oransjetommel.png';
import oransjesykkel from '../../../assets/images/rapidNaming/oransjesykkel.png';

import rodbamse from '../../../assets/images/rapidNaming/rodbamse.png';
import rodgenser from '../../../assets/images/rapidNaming/rodgenser.png';
import rodeple from '../../../assets/images/rapidNaming/rodeple.png';
import rodtommel from '../../../assets/images/rapidNaming/rodtommel.png';
import rodsykkel from '../../../assets/images/rapidNaming/rodsykkel.png';

import gronnbamse from '../../../assets/images/rapidNaming/gronnbamse.png';
import gronngenser from '../../../assets/images/rapidNaming/gronngenser.png';
import gronneple from '../../../assets/images/rapidNaming/gronneple.png';
import gronntommel from '../../../assets/images/rapidNaming/gronntommel.png';
import gronnsykkel from '../../../assets/images/rapidNaming/gronnsykkel.png';

export const rnImages = [
	[rodbamse, rodgenser, rodeple, rodtommel, rodsykkel],
	[blabamse, blagenser, blaeple, blatommel, blasykkel],
	[grabamse, gragenser, graeple, gratommel, grasykkel],
	[oransjebamse, oransjegenser, oransjeeple, oransjetommel, oransjesykkel],
	[gronnbamse, gronngenser, gronneple, gronntommel, gronnsykkel],
];

export const ranIntroItemImages = [
	gratommel,
	graeple,
	grasykkel,
	gragenser,
	grabamse,
];
export const ranIntroImages = [
	blatommel,
	oransjeeple,
	gronnsykkel,
	rodgenser,
	grabamse,
];

export const WMWAnswers = [
	['seng', 'fot'],
	['is', 'ku'],
	['glass', 'saks', 'hus'],
	['bil', 'ku', 'sol'],
	['is', 'blomst', 'fot', 'ball'],
	['bamse', 'øye', 'hest', 'fly'],
	['genser', 'øre', 'stol', 'hand', 'løve'],
	['glass', 'ku', 'måne', 'bil', 'vann'],
	['bukse', 'tv', 'baby', 'eple', 'gave', 'ski'],
	['avis', 'bilde', 'kopp', 'katt', 'snø', 'vegg'],
	['fly', 'penn', 'banan', 'pil', 'blå', 'stjerne', 'boks'],
	['skole', 'jakke', 'kaffe', 'briller', 'sykkel', 'gutt', 'vindu'],
];

export const WMNAnswers = [
	[7, 3],
	[9, 2],
	[8, 5, 1],
	[9, 4, 2],
	[1, 8, 4, 3],
	[3, 7, 2, 6],
	[5, 3, 7, 2, 1],
	[4, 1, 3, 6, 9],
	[1, 9, 5, 2, 7, 8],
	[3, 9, 7, 2, 4, 1],
	[1, 9, 4, 8, 3, 5, 2],
	[2, 7, 4, 1, 7, 9, 6],
];

export const WMTWAnswers = [];

export const WMTNAnswers = [];
export const SpoonerismAnswers = [
	{task: 'Bamse - Sofa', correct: 'Samse - Bofa'},
	{task: 'Lekse - Genser', correct: 'Gekse - Lenser'},
	{task: 'Bilde - Nese', correct: 'Nilde - Bese'},
	{task: 'Sommer - Perle', correct: 'Pommer - Serle'},
	{task: 'Dele - Gardin', correct: 'Gele - Dardin'},
	{task: 'Sykkel - Rose', correct: 'Rykkel - Sose'},
	{task: 'Maske - Dame', correct: 'Daske - Mame'},
	{task: 'Pinne - Teppe', correct: 'Tinne - Peppe'},
	{task: 'Fotball - Kake', correct: 'Kotball - Fake'},
	{task: 'Vindu - Nisse', correct: 'Nindu - Visse'},
	{task: 'Dommer - Brikke', correct: 'Bommer - Drikke'},
	{task: 'Sjelden - Boks', correct: 'Belden - Sjoks'},
	{task: 'Kaffe - Brødre', correct: 'Baffe - Krødre'},
	{task: 'Varme - Hjort', correct: 'Jarme - Vort'},
	{task: 'Hjerte - Bord', correct: 'Berte - Jord'},
	{task: 'Motor - Ballong', correct: 'Botor - Mallong'},
	{task: 'Serviett - Jukse', correct: 'Jerviett - Sukse'},
	{task: 'Lande - Purre', correct: 'Pande - Lurre'},
	{task: 'Kjeks - Mais', correct: 'Meks - Kjais'},
	{task: 'Hår - Skjorte', correct: 'Skjår - Horte'},
];

export const SpoonerismIntroTasks = [
	{task: 'Bok - Dag', correct: 'Dok - Bag'},
	{task: 'Kjole - Fange', correct: 'Fole - Kjange'},
	{task: 'Musikk - Sjakk', correct: 'Sjusikk - Makk'},
];

export const phonemeIsolationIntro = [
	{
		id: 0,
		sound: 'g',
		options: {
			a: 'ball',
			b: 'sweater',
			c: 'fish',
			d: 'ski',
		},
		correct: 'b',
	},
	{
		id: 1,
		sound: 'h',
		options: {
			a: 'telephone',
			b: 'flower',
			c: 'motorcycle',
			d: 'house',
		},
		correct: 'd',
	},
];

export const phonemeIsolationTasks = [
	{
		id: 0,
		sound: 'b',
		options: {
			a: 'apple',
			b: 'bicycle',
			c: 'book',
			d: 'tractor',
		},
		correct: 'c',
		type: 'starts_with_',
	},
	{
		id: 1,
		sound: 'k',
		options: {
			a: 'shoe',
			b: 'cat',
			c: 'flower',
			d: 'plane',
		},
		correct: 'b',
		type: 'starts_with_',
	},
	{
		id: 2,
		sound: 'i',
		options: {
			a: 'icecream',
			b: 'chair',
			c: 'baby',
			d: 'horse',
		},
		correct: 'a',
		type: 'starts_with_',
	},
	{
		id: 3,
		sound: 'oe',
		options: {
			a: 'fireengine',
			b: 'ear',
			c: 'cup',
			d: 'teddy',
		},
		correct: 'b',
		type: 'starts_with_',
	},
	{
		id: 4,
		sound: 's',
		options: {
			a: 'jeans',
			b: 'purse',
			c: 'key',
			d: 'bicycle',
		},
		correct: 'd',
		type: 'starts_with_',
	},
	{
		id: 5,
		options: {
			a: 'crocodile',
			b: 'shoe',
			c: 'ski',
			d: 'key',
		},
		correct: 'b',
		sound: 'o',
		type: 'ends_with_',
	},
	{
		id: 6,
		options: {
			a: 'cup',
			b: 'chair',
			c: 'horse',
			d: 'book',
		},
		correct: 'a',
		sound: 'p',
		type: 'ends_with_',
	},
];
export const phonemeIsolationNormingTasks = [
	{
		id: 0,
		sound: 'b',
		options: {
			a: 'apple',
			b: 'bicycle',
			c: 'book',
			d: 'tractor',
		},
		correct: 'book',
		type: 'starts_with_',
	},
	{
		id: 1,
		sound: 'l',
		options: {
			a: 'foot',
			b: 'doll',
			c: 'nose',
			d: 'lion',
		},
		correct: 'lion',
		type: 'starts_with_',
	},
	{
		id: 2,
		sound: 'k',
		options: {
			a: 'shoe',
			b: 'cat',
			c: 'flower',
			d: 'plane',
		},
		correct: 'cat',
		type: 'starts_with_',
	},
	{
		id: 3,
		sound: 'e',
		options: {
			a: 'elephant',
			b: 'spoon',
			c: 'bed',
			d: 'fish',
		},
		correct: 'elephant',
		type: 'starts_with_',
	},
	{
		id: 4,
		sound: 'i',
		options: {
			a: 'horse',
			b: 'chair',
			c: 'baby',
			d: 'icecream',
		},
		correct: 'icecream',
		type: 'starts_with_',
	},
	{
		id: 5,
		sound: 'v',
		options: {
			a: 'rabbit',
			b: 'gloves',
			c: 'tractor',
			d: 'boot',
		},
		correct: 'gloves',
		type: 'starts_with_',
	},
	{
		id: 6,
		sound: 'oe',
		options: {
			a: 'fireengine',
			b: 'ear',
			c: 'cup',
			d: 'teddy',
		},
		correct: 'ear',
		type: 'starts_with_',
	},
	{
		id: 7,
		sound: 's',
		options: {
			a: 'jeans',
			b: 'purse',
			c: 'key',
			d: 'bicycle',
		},
		correct: 'bicycle',
		type: 'starts_with_',
	},
	{
		id: 8,
		sound: 't',
		options: {
			a: 'foot',
			b: 'eye',
			c: 'sun',
			d: 'bed',
		},
		correct: 'foot',
		type: 'ends_with_',
	},
	{
		id: 9,
		options: {
			a: 'crocodile',
			b: 'shoe',
			c: 'ski',
			d: 'key',
		},
		correct: 'shoe',
		sound: 'o',
		type: 'ends_with_',
	},
	{
		id: 10,
		options: {
			a: 'jeans',
			b: 'banana',
			c: 'sweater',
			d: 'saft',
		},
		correct: 'banana',
		sound: 'n',
		type: 'ends_with_',
	},
	{
		id: 11,
		options: {
			a: 'tractor',
			b: 'fish',
			c: 'icecream',
			d: 'cat',
		},
		correct: 'tractor',
		sound: 'r',
		type: 'ends_with_',
	},
	{
		id: 12,
		options: {
			a: 'reflex',
			b: 'plane',
			c: 'book',
			d: 'plowtruck',
		},
		correct: 'plane',
		sound: 'y',
		type: 'ends_with_',
	},
	{
		id: 13,
		options: {
			a: 'chair',
			b: 'cup',
			c: 'horse',
			d: 'book',
		},
		correct: 'cup',
		sound: 'p',
		type: 'ends_with_',
	},
	{
		id: 14,
		options: {
			a: 'bicycle',
			b: 'package',
			c: 'finger',
			d: 'fireengine',
		},
		correct: 'package',
		sound: 'e',
		type: 'ends_with_',
	},
	{
		id: 15,
		options: {a: 'horse', b: 'cup', c: 'porrige', d: 'cow'},
		correct: 'cow',
		sound: 'u',
		type: 'ends_with_',
	},
];

export const oneMinuteTasks = [
	'har',
	'og',
	'til',
	'den',
	'med',
	'ikke',
	'vil',
	'seg',
	'hør',
	'bok',
	'hun',
	'være',
	'blir',
	'skal',
	'over',
	'hus',
	'eller',
	'andre',
	'bare',
	'kroner',
	'konge',
	'mellom',
	'spise',
	'penn',
	'første',
	'gjøre',
	'skole',
	'norsk',
	'hvorfor',
	'store',
	'hvete',
	'godt',
	'flagg',
	'forteller',
	'tidligere',
	'rundt',
	'gjennom',
	'sjakk',
	'derfor',
	'hvordan',
	'allerede',
	'fortsatt',
	'samtidig',
	'viktig',
	'hvert',
	'mulig',
	'bjørn',
	'kjole',
	'kanskje',
	'desember',
	'vanskelig',
	'linjal',
	'skjorte',
	'kikkert',
	'hverandre',
	'kjøpe',
	'spørsmål',
	'akkurat',
	'egentlig',
	'keramikk',
	'tigger',
	'selskapet',
	'skjedde',
	'kylling',
	'autograf',
	'kiste',
	'hjort',
	'hjerte',
	'pennal',
	'lørdag',
	'gjær',
	'kakao',
	'spiseskje',
	'kjøtt',
	'utvikling',
	'elefant',
	'ryggsekk',
	'sjiraff',
	'hjort',
	'gardinene',
	'leverpostei',
	'helikopter',
	'rabarbra',
	'tyggegummi',
	'forskjellige',
	'trampoline',
	'proteiner',
	'fortau',
	'musikk',
	'helomvending',
	'slikkepott',
	'situasjonen',
	'ambivalent',
	'kravstor',
	'segne',
	'binders',
	'fluelort',
	'pekefinger',
	'landsforræder',
	'bilmekaniker',
	'skjære',
	'perforere',
	'sammensetning',
	'hallusinasjon',
	'spekulasjon',
	'kildesortering',
	'akvakultur',
	'avdramatisere',
	'dyskalkuli',
	'makulering',
	'ukulele',
	'klinkekule',
	'beredskapsplan',
	'byggeindustri',
	'kjekjøtt',
	'skjermdump',
	'lakseoppdrett',
	'utdanningsprogram',
	'beskjedent',
	'riksmeklingsmannen',
];

export const lkAnswersNorming = [
	'O',
	'O',
	'T',
	'T',
	'W',
	'W',
	'H',
	'H',
	'C',
	'C',
	'E',
	'E',
	'Y',
	'Y',
	'M',
	'M',
	'B',
	'B',
	'Z',
	'Z',
	'Ø',
	'Ø',
	'V',
	'V',
	'K',
	'K',
	'G',
	'G',
	'R',
	'R',
	'S',
	'S',
	'Æ',
	'Æ',
	'F',
	'F',
	'I',
	'I',
	'P',
	'P',
	'J',
	'J',
	'L',
	'L',
	'D',
	'D',
	'N',
	'N',
	'Å',
	'Å',
	'Q',
	'Q',
	'U',
	'U',
	'X',
	'X',
	'A',
	'A',
];
export const lkAnswers = [
	'O',
	'O',
	'E',
	'E',
	'I',
	'I',
	'S',
	'S',
	'T',
	'T',
	'U',
	'U',
	'N',
	'N',
	'Å',
	'Å',
	'G',
	'G',
	'D',
	'D',
	'Æ',
	'Æ',
	'Q',
	'Q',
];
export const kdlkAnswers = [
	'H',
	'H',
	'E',
	'E',
	'M',
	'M',
	'B',
	'B',
	'G',
	'G',
	'R',
	'R',
	'S',
	'S',
	'Æ',
	'Æ',
	'F',
	'F',
	'I',
	'I',
	'J',
	'J',
	'L',
	'L',
	'D',
	'D',
	'N',
	'N',
	'U',
	'U',
];
export const pdIntroSteps = [
	{
		task: 'Kan du si "gris"?',
		expectedAnswer: 'gris',
	},
	{
		task: 'Kan du si "gris" uten /g/?',
		expectedAnswer: 'ris',
	},
	{
		task: 'Kan du si "føre"?',
		expectedAnswer: 'føre',
	},
	{
		task: 'Kan du si "føre" uten /f/?',
		expectedAnswer: 'øre',
	},
	{
		task: 'Kan du si "sel"?',
		expectedAnswer: 'sel',
	},
	{
		task: 'Kan du si "sel" uten /l/?',
		expectedAnswer: 'se',
	},
];
export const pdSteps = [
	'gås',
	'mål',
	'brød',
	'klær',
	'brev',
	'trygg',
	'drap',
	'skyll',
	'lås',
	'bro',
	'klatre',
	'malt',
	'bry',
	'lekse',
	'sport',
	'loff',
];
export const pdAnswers = [
	'ås',
	'ål',
	'rød',
	'lær',
	'rev',
	'rygg',
	'rap',
	'sky',
	'lå',
	'bo',
	'klare',
	'mat',
	'by',
	'lese',
	'sort',
	'lo',
];

export const wdAnswersNorming = [
	'mase',
	'fly',
	'dra',
	'kråka',
	'melk',
	'tær',
	'rikke',
	'gråt',
	'bake',
	'regne',
	'hyle',
	'takk',
	'hvordan',
	'verden',
	'ekkelt',
	'magisk',
	'ski',
	'leksefri',
	'historier',
	'flokk',
	'senger',
	'kanskje',
	'sjokolade',
	'hjul',
	'rundt',
	'sild',
	'skjelven',
	'godt',
	'kyllinger',
	'kjøtt',
	'smarttelefon',
	'fugl',
	'sykkelhjelm',
	'hjerte',
	'kjeveortoped',
	'blåbærsyltetøy',
	'biblioteket',
	'kjetting',
	'sannsynlighet',
	'abrakadabra',
];

export const wdAnswers = [
	'hvordan',
	'verden',
	'magisk',
	'ski',
	'leksefri',
	'flokk',
	'kanskje',
	'rundt',
	'kyllinger',
	'blåbærsyltetøy',
	'biblioteket',
	'sannsynlighet',
	'abrakadabra',
];

export const rnIntroSteps = [
	'Intro',
	'Colors',
	'Images',
	'Colors and Images',
	'Ready to start',
];

export const rnItems = ['bike', 'teddy', 'apple', 'sweater', 'thumb'];
export const rnColors = ['red', 'blue', 'grey', 'orange', 'green'];

export const rnAnswers = [
	[
		{color: 0, item: 0},
		{color: 1, item: 1},
		{color: 2, item: 2},
		{color: 0, item: 0},
		{color: 1, item: 3},
		{color: 3, item: 4},
		{color: 4, item: 0},
	],
	[
		{color: 2, item: 4},
		{color: 0, item: 2},
		{color: 3, item: 0},
		{color: 3, item: 4},
		{color: 1, item: 3},
		{color: 4, item: 1},
		{color: 1, item: 4},
	],
	[
		{color: 2, item: 2},
		{color: 0, item: 1},
		{color: 0, item: 3},
		{color: 4, item: 0},
		{color: 2, item: 0},
		{color: 1, item: 3},
		{color: 4, item: 2},
	],
	[
		{color: 3, item: 4},
		{color: 0, item: 3},
		{color: 1, item: 2},
		{color: 4, item: 1},
		{color: 1, item: 4},
		{color: 4, item: 1},
		{color: 3, item: 2},
	],
	[
		{color: 2, item: 4},
		{color: 0, item: 0},
		{color: 3, item: 0},
		{color: 2, item: 3},
		{color: 3, item: 0},
		{color: 2, item: 3},
		{color: 3, item: 4},
	],
	[
		{color: 0, item: 1},
		{color: 4, item: 2},
		{color: 2, item: 1},
		{color: 4, item: 3},
		{color: 3, item: 2},
		{color: 0, item: 3},
		{color: 4, item: 2},
	],
	[
		{color: 2, item: 4},
		{color: 1, item: 1},
		{color: 2, item: 0},
		{color: 3, item: 4},
		{color: 1, item: 3},
		{color: 4, item: 1},
		{color: 0, item: 2},
		{color: 1, item: 1},
	],
];

export const kdrnAnswers = [
	[
		{color: 0, item: 0},
		{color: 1, item: 1},
		{color: 2, item: 2},
		{color: 0, item: 0},
		{color: 1, item: 3},
		{color: 3, item: 4},
	],
	[
		{color: 4, item: 0},
		{color: 2, item: 4},
		{color: 0, item: 2},
		{color: 3, item: 0},
		{color: 3, item: 4},
		{color: 1, item: 3},
	],
	[
		{color: 4, item: 1},
		{color: 1, item: 4},
		{color: 2, item: 2},
		{color: 0, item: 1},
		{color: 0, item: 3},
		{color: 4, item: 0},
	],
	[
		{color: 2, item: 0},
		{color: 1, item: 3},
		{color: 4, item: 2},
		{color: 3, item: 4},
		{color: 0, item: 3},
		{color: 1, item: 2},
		{color: 4, item: 1},
	],
];
