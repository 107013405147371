import audio0 from '../../../assets/sounds/no/dictation/diktat-0.mp3';
import audio1 from '../../../assets/sounds/no/dictation/diktat-1.mp3';
import audio2 from '../../../assets/sounds/no/dictation/diktat-2.mp3';
import audio3 from '../../../assets/sounds/no/dictation/diktat-3.mp3';
import audio4 from '../../../assets/sounds/no/dictation/diktat-4.mp3';
import audio5 from '../../../assets/sounds/no/dictation/diktat-5.mp3';
import audio6 from '../../../assets/sounds/no/dictation/diktat-6.mp3';
import audio7 from '../../../assets/sounds/no/dictation/diktat-7.mp3';
import audio8 from '../../../assets/sounds/no/dictation/diktat-8.mp3';
import audio9 from '../../../assets/sounds/no/dictation/diktat-9.mp3';
import audio10 from '../../../assets/sounds/no/dictation/diktat-10.mp3';
import audio11 from '../../../assets/sounds/no/dictation/diktat-11.mp3';
import audio14 from '../../../assets/sounds/no/dictation/diktat-14.mp3';
import audio18 from '../../../assets/sounds/no/dictation/diktat-18.mp3';
import audio20 from '../../../assets/sounds/no/dictation/diktat-20.mp3';
import audio22 from '../../../assets/sounds/no/dictation/diktat-22.mp3';
import audio23 from '../../../assets/sounds/no/dictation/diktat-23.mp3';
import audio25 from '../../../assets/sounds/no/dictation/diktat-25.mp3';
import audio26 from '../../../assets/sounds/no/dictation/diktat-26.mp3';
import audio27 from '../../../assets/sounds/no/dictation/diktat-27.mp3';
import audio29 from '../../../assets/sounds/no/dictation/diktat-29.mp3';
import audio30 from '../../../assets/sounds/no/dictation/diktat-30.mp3';
import audio31 from '../../../assets/sounds/no/dictation/diktat-31.mp3';
import audio32 from '../../../assets/sounds/no/dictation/diktat-32.mp3';
import audio33 from '../../../assets/sounds/no/dictation/diktat-33.mp3';
import audio34 from '../../../assets/sounds/no/dictation/diktat-34.mp3';
import audio37 from '../../../assets/sounds/no/dictation/diktat-37.mp3';
import audio12 from '../../../assets/sounds/nn/dictation/diktat-12.mp3';
import audio13 from '../../../assets/sounds/nn/dictation/diktat-13.mp3';
import audio15 from '../../../assets/sounds/nn/dictation/diktat-15.mp3';
import audio16 from '../../../assets/sounds/nn/dictation/diktat-16.mp3';
import audio17 from '../../../assets/sounds/nn/dictation/diktat-17.mp3';
import audio19 from '../../../assets/sounds/nn/dictation/diktat-19.mp3';
import audio21 from '../../../assets/sounds/nn/dictation/diktat-21.mp3';
import audio24 from '../../../assets/sounds/nn/dictation/diktat-24.mp3';
import audio28 from '../../../assets/sounds/nn/dictation/diktat-28.mp3';
import audio35 from '../../../assets/sounds/nn/dictation/diktat-35.mp3';
import audio36 from '../../../assets/sounds/nn/dictation/diktat-36.mp3';
import audio38 from '../../../assets/sounds/nn/dictation/diktat-38.mp3';
import audio39 from '../../../assets/sounds/nn/dictation/diktat-39.mp3';

export const normingAudioFiles = {
	0: audio0,
	1: audio1,
	2: audio2,
	3: audio3,
	4: audio4,
	5: audio5,
	6: audio6,
	7: audio7,
	8: audio8,
	9: audio9,
	10: audio10,
	11: audio11,
	12: audio12,
	13: audio13,
	14: audio14,
	15: audio15,
	16: audio16,
	17: audio17,
	18: audio18,
	19: audio19,
	20: audio20,
	21: audio21,
	22: audio22,
	23: audio23,
	24: audio24,
	25: audio25,
	26: audio26,
	27: audio27,
	28: audio28,
	29: audio29,
	30: audio30,
	31: audio31,
	32: audio32,
	33: audio33,
	34: audio34,
	35: audio35,
	36: audio36,
	37: audio37,
	38: audio38,
	39: audio39,
};

export const audioFiles = {
	0: audio12,
	1: audio13,
	2: audio15,
	3: audio16,
	4: audio17,
	5: audio19,
	6: audio21,
	7: audio24,
	8: audio28,
	9: audio35,
	10: audio36,
	11: audio38,
	12: audio39,
};
