import React, {Component, Fragment} from 'react';
import ReadyButton from '../../components/ReadyButton';
import AudioPlayer from '../../components/AudioPlayer';

import om_introNo from '../../assets/sounds/no/intros/oneMinute-intro.mp3';
import om_introNn from '../../assets/sounds/nn/intros/oneMinute-intro.mp3';
import om_introSe from '../../assets/sounds/se/intros/oneMinute-intro.mp3';
import om_introDe from '../../assets/sounds/de/intros/oneMinute-intro.wav';
import strings from '../../strings';

class Intro extends Component {
	constructor(props) {
		super(props);

		switch (strings.getLanguage()) {
			case 'nn':
				this.om_intro = om_introNn;
			case 'se':
				this.om_intro = om_introSe;
				break;
			case 'de':
				this.om_intro = om_introDe;
				break;

			case 'no':
			default:
				this.om_intro = om_introNo;
				break;
		}
	}

	render() {
		return (
			<Fragment>
				<p>
					<AudioPlayer autoplay inline src={this.om_intro} />
					{strings.om_intro}
				</p>
				<ReadyButton onReadyChange={this.props.setReady} />
			</Fragment>
		);
	}
}

export default Intro;
