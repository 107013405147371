import {Howl} from 'howler';

import no_spoonerismIntro from '../../assets/sounds/no/spoonerism/intro.mp3';
import no_spoonerismIntroEnd from '../../assets/sounds/no/spoonerism/intro-end.mp3';
import no_proveoppgave1 from '../../assets/sounds/no/spoonerism/proveoppgave-1.mp3';
import no_proveoppgave2 from '../../assets/sounds/no/spoonerism/proveoppgave-2.mp3';
import no_proveoppgave3 from '../../assets/sounds/no/spoonerism/proveoppgave-3.mp3';
import no_correct1 from '../../assets/sounds/no/spoonerism/correct-1.mp3';
import no_correct2 from '../../assets/sounds/no/spoonerism/correct-2.mp3';
import no_correct3 from '../../assets/sounds/no/spoonerism/correct-3.mp3';
import no_incorrect1 from '../../assets/sounds/no/spoonerism/incorrect-1.mp3';
import no_incorrect2 from '../../assets/sounds/no/spoonerism/incorrect-2.mp3';
import no_incorrect3 from '../../assets/sounds/no/spoonerism/incorrect-3.mp3';
import no_sound1 from '../../assets/sounds/no/spoonerism/spoonerism-task (1).mp3';
import no_sound2 from '../../assets/sounds/no/spoonerism/spoonerism-task (2).mp3';
import no_sound3 from '../../assets/sounds/no/spoonerism/spoonerism-task (3).mp3';
import no_sound4 from '../../assets/sounds/no/spoonerism/spoonerism-task (4).mp3';
import no_sound5 from '../../assets/sounds/no/spoonerism/spoonerism-task (5).mp3';
import no_sound6 from '../../assets/sounds/no/spoonerism/spoonerism-task (6).mp3';
import no_sound7 from '../../assets/sounds/no/spoonerism/spoonerism-task (7).mp3';
import no_sound8 from '../../assets/sounds/no/spoonerism/spoonerism-task (8).mp3';
import no_sound9 from '../../assets/sounds/no/spoonerism/spoonerism-task (9).mp3';
import no_sound10 from '../../assets/sounds/no/spoonerism/spoonerism-task (10).mp3';
import no_sound11 from '../../assets/sounds/no/spoonerism/spoonerism-task (11).mp3';
import no_sound12 from '../../assets/sounds/no/spoonerism/spoonerism-task (12).mp3';
import no_sound13 from '../../assets/sounds/no/spoonerism/spoonerism-task (13).mp3';
import no_sound14 from '../../assets/sounds/no/spoonerism/spoonerism-task (14).mp3';
import no_sound15 from '../../assets/sounds/no/spoonerism/spoonerism-task (15).mp3';
import no_sound16 from '../../assets/sounds/no/spoonerism/spoonerism-task (16).mp3';
import no_sound17 from '../../assets/sounds/no/spoonerism/spoonerism-task (17).mp3';
import no_sound18 from '../../assets/sounds/no/spoonerism/spoonerism-task (18).mp3';
import no_sound19 from '../../assets/sounds/no/spoonerism/spoonerism-task (19).mp3';
import no_sound20 from '../../assets/sounds/no/spoonerism/spoonerism-task (20).mp3';

import nn_spoonerismIntro from '../../assets/sounds/nn/spoonerism/intro.mp3';
import nn_spoonerismIntroEnd from '../../assets/sounds/nn/spoonerism/intro-end.mp3';
import nn_proveoppgave1 from '../../assets/sounds/nn/spoonerism/proveoppgave-1.mp3';
import nn_proveoppgave2 from '../../assets/sounds/nn/spoonerism/proveoppgave-2.mp3';
import nn_proveoppgave3 from '../../assets/sounds/nn/spoonerism/proveoppgave-3.mp3';
import nn_correct1 from '../../assets/sounds/nn/spoonerism/correct-1.mp3';
import nn_correct2 from '../../assets/sounds/nn/spoonerism/correct-2.mp3';
import nn_correct3 from '../../assets/sounds/nn/spoonerism/correct-3.mp3';
import nn_incorrect1 from '../../assets/sounds/nn/spoonerism/incorrect-1.mp3';
import nn_incorrect2 from '../../assets/sounds/nn/spoonerism/incorrect-2.mp3';
import nn_incorrect3 from '../../assets/sounds/nn/spoonerism/incorrect-3.mp3';
import nn_sound1 from '../../assets/sounds/nn/spoonerism/spoonerism-task (1).mp3';
import nn_sound2 from '../../assets/sounds/nn/spoonerism/spoonerism-task (2).mp3';
import nn_sound3 from '../../assets/sounds/nn/spoonerism/spoonerism-task (3).mp3';
import nn_sound4 from '../../assets/sounds/nn/spoonerism/spoonerism-task (4).mp3';
import nn_sound5 from '../../assets/sounds/nn/spoonerism/spoonerism-task (5).mp3';
import nn_sound6 from '../../assets/sounds/nn/spoonerism/spoonerism-task (6).mp3';
import nn_sound7 from '../../assets/sounds/nn/spoonerism/spoonerism-task (7).mp3';
import nn_sound8 from '../../assets/sounds/nn/spoonerism/spoonerism-task (8).mp3';
import nn_sound9 from '../../assets/sounds/nn/spoonerism/spoonerism-task (9).mp3';
import nn_sound10 from '../../assets/sounds/nn/spoonerism/spoonerism-task (10).mp3';
import nn_sound11 from '../../assets/sounds/nn/spoonerism/spoonerism-task (11).mp3';

import se_spoonerismIntro from '../../assets/sounds/se/spoonerism/intro.mp3';
import se_spoonerismIntroEnd from '../../assets/sounds/se/spoonerism/intro-end.mp3';
import se_proveoppgave1 from '../../assets/sounds/se/spoonerism/proveoppgave-1.mp3';
import se_proveoppgave2 from '../../assets/sounds/se/spoonerism/proveoppgave-2.mp3';
import se_proveoppgave3 from '../../assets/sounds/se/spoonerism/proveoppgave-3.mp3';
import se_correct1 from '../../assets/sounds/se/spoonerism/correct-1.mp3';
import se_correct2 from '../../assets/sounds/se/spoonerism/correct-2.mp3';
import se_correct3 from '../../assets/sounds/se/spoonerism/correct-3.mp3';
import se_incorrect1 from '../../assets/sounds/se/spoonerism/incorrect-1.mp3';
import se_incorrect2 from '../../assets/sounds/se/spoonerism/incorrect-2.mp3';
import se_incorrect3 from '../../assets/sounds/se/spoonerism/incorrect-3.mp3';
import se_sound1 from '../../assets/sounds/se/spoonerism/spoonerism-task (1).mp3';
import se_sound2 from '../../assets/sounds/se/spoonerism/spoonerism-task (2).mp3';
import se_sound3 from '../../assets/sounds/se/spoonerism/spoonerism-task (3).mp3';
import se_sound4 from '../../assets/sounds/se/spoonerism/spoonerism-task (4).mp3';
import se_sound5 from '../../assets/sounds/se/spoonerism/spoonerism-task (5).mp3';
import se_sound6 from '../../assets/sounds/se/spoonerism/spoonerism-task (6).mp3';
import se_sound7 from '../../assets/sounds/se/spoonerism/spoonerism-task (7).mp3';
import se_sound8 from '../../assets/sounds/se/spoonerism/spoonerism-task (8).mp3';
import se_sound9 from '../../assets/sounds/se/spoonerism/spoonerism-task (9).mp3';
import se_sound10 from '../../assets/sounds/se/spoonerism/spoonerism-task (10).mp3';
import se_sound11 from '../../assets/sounds/se/spoonerism/spoonerism-task (11).mp3';
import se_sound12 from '../../assets/sounds/se/spoonerism/spoonerism-task (12).mp3';
import se_sound13 from '../../assets/sounds/se/spoonerism/spoonerism-task (13).mp3';
import se_sound14 from '../../assets/sounds/se/spoonerism/spoonerism-task (14).mp3';
import se_sound15 from '../../assets/sounds/se/spoonerism/spoonerism-task (15).mp3';
import se_sound16 from '../../assets/sounds/se/spoonerism/spoonerism-task (16).mp3';
import se_sound17 from '../../assets/sounds/se/spoonerism/spoonerism-task (17).mp3';
import se_sound18 from '../../assets/sounds/se/spoonerism/spoonerism-task (18).mp3';
import se_sound19 from '../../assets/sounds/se/spoonerism/spoonerism-task (19).mp3';
import se_sound20 from '../../assets/sounds/se/spoonerism/spoonerism-task (20).mp3';

import de_spoonerismIntro from '../../assets/sounds/de/spoonerism/intro.mp3';
import de_spoonerismIntroEnd from '../../assets/sounds/de/spoonerism/intro-end.mp3';
import de_proveoppgave1 from '../../assets/sounds/de/spoonerism/proveoppgave-1.wav';
import de_proveoppgave2 from '../../assets/sounds/de/spoonerism/proveoppgave-2.wav';
import de_proveoppgave3 from '../../assets/sounds/de/spoonerism/proveoppgave-3.mp3';

import de_correct1 from '../../assets/sounds/de/spoonerism/correct-1.mp3';
import de_correct2 from '../../assets/sounds/de/spoonerism/correct-2.mp3';
import de_correct3 from '../../assets/sounds/de/spoonerism/correct-3.mp3';
import de_incorrect1 from '../../assets/sounds/de/spoonerism/incorrect-1.mp3';
import de_incorrect2 from '../../assets/sounds/de/spoonerism/incorrect-2.mp3';
import de_incorrect3 from '../../assets/sounds/de/spoonerism/incorrect-3.mp3';
import de_sound1 from '../../assets/sounds/de/spoonerism/spoonerism-task (1).wav';
import de_sound2 from '../../assets/sounds/de/spoonerism/spoonerism-task (2).wav';
import de_sound3 from '../../assets/sounds/de/spoonerism/spoonerism-task (3).wav';
import de_sound4 from '../../assets/sounds/de/spoonerism/spoonerism-task (4).wav';
import de_sound5 from '../../assets/sounds/de/spoonerism/spoonerism-task (5).wav';
import de_sound6 from '../../assets/sounds/de/spoonerism/spoonerism-task (6).wav';
import de_sound7 from '../../assets/sounds/de/spoonerism/spoonerism-task (7).wav';
import de_sound8 from '../../assets/sounds/de/spoonerism/spoonerism-task (8).wav';
import de_sound9 from '../../assets/sounds/de/spoonerism/spoonerism-task (9).wav';
import de_sound10 from '../../assets/sounds/de/spoonerism/spoonerism-task (10).wav';
import de_sound11 from '../../assets/sounds/de/spoonerism/spoonerism-task (11).wav';
import de_sound12 from '../../assets/sounds/de/spoonerism/spoonerism-task (12).wav';
import de_sound13 from '../../assets/sounds/de/spoonerism/spoonerism-task (13).wav';
import de_sound14 from '../../assets/sounds/de/spoonerism/spoonerism-task (14).wav';
import de_sound15 from '../../assets/sounds/de/spoonerism/spoonerism-task (15).wav';
import de_sound16 from '../../assets/sounds/de/spoonerism/spoonerism-task (16).wav';
import de_sound17 from '../../assets/sounds/de/spoonerism/spoonerism-task (17).wav';
import de_sound18 from '../../assets/sounds/de/spoonerism/spoonerism-task (18).wav';
import de_sound19 from '../../assets/sounds/de/spoonerism/spoonerism-task (19).wav';
import de_sound20 from '../../assets/sounds/de/spoonerism/spoonerism-task (20).wav';

export const no = {
	spoonerismIntro: new Howl({src: no_spoonerismIntro}),
	spoonerismIntroEnd: new Howl({src: no_spoonerismIntroEnd}),
	proveoppgave1: new Howl({src: no_proveoppgave1}),
	proveoppgave2: new Howl({src: no_proveoppgave2}),
	proveoppgave3: new Howl({src: no_proveoppgave3}),
	correct1: new Howl({src: no_correct1}),
	correct2: new Howl({src: no_correct2}),
	correct3: new Howl({src: no_correct3}),
	incorrect1: new Howl({src: no_incorrect1}),
	incorrect2: new Howl({src: no_incorrect2}),
	incorrect3: new Howl({src: no_incorrect3}),
};

export const nn = {
	spoonerismIntro: new Howl({src: nn_spoonerismIntro}),
	spoonerismIntroEnd: new Howl({src: nn_spoonerismIntroEnd}),
	proveoppgave1: new Howl({src: nn_proveoppgave1}),
	proveoppgave2: new Howl({src: nn_proveoppgave2}),
	proveoppgave3: new Howl({src: nn_proveoppgave3}),
	correct1: new Howl({src: nn_correct1}),
	correct2: new Howl({src: nn_correct2}),
	correct3: new Howl({src: nn_correct3}),
	incorrect1: new Howl({src: nn_incorrect1}),
	incorrect2: new Howl({src: nn_incorrect2}),
	incorrect3: new Howl({src: nn_incorrect3}),
};

export const se = {
	spoonerismIntro: new Howl({src: se_spoonerismIntro}),
	spoonerismIntroEnd: new Howl({src: se_spoonerismIntroEnd}),
	proveoppgave1: new Howl({src: se_proveoppgave1}),
	proveoppgave2: new Howl({src: se_proveoppgave2}),
	proveoppgave3: new Howl({src: se_proveoppgave3}),
	correct1: new Howl({src: se_correct1}),
	correct2: new Howl({src: se_correct2}),
	correct3: new Howl({src: se_correct3}),
	incorrect1: new Howl({src: se_incorrect1}),
	incorrect2: new Howl({src: se_incorrect2}),
	incorrect3: new Howl({src: se_incorrect3}),
};

export const de = {
	spoonerismIntro: new Howl({src: de_spoonerismIntro}),
	spoonerismIntroEnd: new Howl({src: de_spoonerismIntroEnd}),
	proveoppgave1: new Howl({src: de_proveoppgave1}),
	proveoppgave2: new Howl({src: de_proveoppgave2}),
	proveoppgave3: new Howl({src: de_proveoppgave3}),
	correct1: new Howl({src: de_correct1}),
	correct2: new Howl({src: de_correct2}),
	correct3: new Howl({src: de_correct3}),
	incorrect1: new Howl({src: de_incorrect1}),
	incorrect2: new Howl({src: de_incorrect2}),
	incorrect3: new Howl({src: de_incorrect3}),
};

export const no_test = {
	sound1: new Howl({src: no_sound1}),
	sound2: new Howl({src: no_sound2}),
	sound3: new Howl({src: no_sound3}),
	sound4: new Howl({src: no_sound4}),
	sound5: new Howl({src: no_sound5}),
	sound6: new Howl({src: no_sound6}),
	sound7: new Howl({src: no_sound7}),
	sound8: new Howl({src: no_sound8}),
	sound9: new Howl({src: no_sound9}),
	sound10: new Howl({src: no_sound10}),
	sound11: new Howl({src: no_sound11}),
	sound12: new Howl({src: no_sound12}),
	sound13: new Howl({src: no_sound13}),
	sound14: new Howl({src: no_sound14}),
	sound15: new Howl({src: no_sound15}),
	sound16: new Howl({src: no_sound16}),
	sound17: new Howl({src: no_sound17}),
	sound18: new Howl({src: no_sound18}),
	sound19: new Howl({src: no_sound19}),
	sound20: new Howl({src: no_sound20}),
	sound21: new Howl({src: no_sound20}),
	sound22: new Howl({src: no_sound20}),
	sound23: new Howl({src: no_sound20}),
	sound24: new Howl({src: no_sound20}),
	sound25: new Howl({src: no_sound20}),
};

export const nn_test = {
	sound1: new Howl({src: nn_sound1}),
	sound2: new Howl({src: nn_sound2}),
	sound3: new Howl({src: nn_sound3}),
	sound4: new Howl({src: nn_sound4}),
	sound5: new Howl({src: nn_sound5}),
	sound6: new Howl({src: nn_sound6}),
	sound7: new Howl({src: nn_sound7}),
	sound8: new Howl({src: nn_sound8}),
	sound9: new Howl({src: nn_sound9}),
	sound10: new Howl({src: nn_sound10}),
	sound11: new Howl({src: nn_sound11}),
	sound12: new Howl({src: no_sound12}),
	sound13: new Howl({src: no_sound13}),
	sound14: new Howl({src: no_sound14}),
	sound15: new Howl({src: no_sound15}),
	sound16: new Howl({src: no_sound16}),
	sound17: new Howl({src: no_sound17}),
	sound18: new Howl({src: no_sound18}),
	sound19: new Howl({src: no_sound19}),
	sound20: new Howl({src: no_sound20}),
	sound21: new Howl({src: no_sound20}),
	sound22: new Howl({src: no_sound20}),
	sound23: new Howl({src: no_sound20}),
	sound24: new Howl({src: no_sound20}),
	sound25: new Howl({src: no_sound20}),
};

export const se_test = {
	sound1: new Howl({src: se_sound1}),
	sound2: new Howl({src: se_sound2}),
	sound3: new Howl({src: se_sound3}),
	sound4: new Howl({src: se_sound4}),
	sound5: new Howl({src: se_sound5}),
	sound6: new Howl({src: se_sound6}),
	sound7: new Howl({src: se_sound7}),
	sound8: new Howl({src: se_sound8}),
	sound9: new Howl({src: se_sound9}),
	sound10: new Howl({src: se_sound10}),
	sound11: new Howl({src: se_sound11}),
	sound12: new Howl({src: se_sound12}),
	sound13: new Howl({src: se_sound13}),
	sound14: new Howl({src: se_sound14}),
	sound15: new Howl({src: se_sound15}),
	sound16: new Howl({src: se_sound16}),
	sound17: new Howl({src: se_sound17}),
	sound18: new Howl({src: se_sound18}),
	sound19: new Howl({src: se_sound19}),
	sound20: new Howl({src: se_sound20}),
	sound21: new Howl({src: se_sound20}),
	sound22: new Howl({src: se_sound20}),
	sound23: new Howl({src: se_sound20}),
	sound24: new Howl({src: se_sound20}),
	sound25: new Howl({src: se_sound20}),
};

export const de_test = {
	sound1: new Howl({src: de_sound1}),
	sound2: new Howl({src: de_sound2}),
	sound3: new Howl({src: de_sound3}),
	sound4: new Howl({src: de_sound4}),
	sound5: new Howl({src: de_sound5}),
	sound6: new Howl({src: de_sound6}),
	sound7: new Howl({src: de_sound7}),
	sound8: new Howl({src: de_sound8}),
	sound9: new Howl({src: de_sound9}),
	sound10: new Howl({src: de_sound10}),
	sound11: new Howl({src: de_sound11}),
	sound12: new Howl({src: de_sound12}),
	sound13: new Howl({src: de_sound13}),
	sound14: new Howl({src: de_sound14}),
	sound15: new Howl({src: de_sound15}),
	sound16: new Howl({src: de_sound16}),
	sound17: new Howl({src: de_sound17}),
	sound18: new Howl({src: de_sound18}),
	sound19: new Howl({src: de_sound19}),
	sound20: new Howl({src: de_sound20}),
	sound21: new Howl({src: de_sound20}),
	sound22: new Howl({src: de_sound20}),
	sound23: new Howl({src: de_sound20}),
	sound24: new Howl({src: de_sound20}),
	sound25: new Howl({src: de_sound20}),
};
