import React from 'react';

import {getLetterKnowledge} from '../../constants/tests.js';

import AdminTestPanel from '../../components/AdminTestPanel';
import strings from '../../strings';
import AdminReadyButton from '../../components/AdminReadyButton';

class LetterKnowledgeAdmin extends AdminTestPanel {
	constructor(props) {
		super(props, {
			enableTimers: false,
		});
	}

	getTestName = () => {
		return strings.lk_testname;
	};

	renderIntro = () => {
		return (
			<div>
				<p>{strings.lk_adminIntro1}</p>
				<p>{strings.clickStartWhenReady}</p>
				<AdminReadyButton
					remoteIsReady={this.props.candidateReady}
					onClick={this.props.nextStage}
				/>
			</div>
		);
	};

	renderTest = () => {
		const {currentStep} = this.state;
		const steps = getLetterKnowledge(
			this.props.testKeyType,
			this.props.ageGroup
		).getSteps();
		const isName = currentStep % 2 === 0;
		const correctString = `${steps[currentStep]} (${
			isName ? strings.name : strings.sound
		})`;

		return (
			<div>
				<p>
					<br />
					{strings.lk_admin1}
					<br />
					{strings.lk_admin2}
					<br />
					{strings.hotkeyCorrect} <br /> {strings.hotkeyWrong}
				</p>
				<p>
					{strings.formatString(
						strings.taskNumOf,
						currentStep + 1,
						steps.length
					)}
				</p>
				<h3>
					{strings.formatString(strings.correctAnswer, correctString)}
				</h3>
				<button
					onClick={(e) => {
						e.stopPropagation();
						this.answerCorrect();
					}}
				>
					{strings.hotkeyButtonCorrect}
				</button>
				<button
					onClick={(e) => {
						e.stopPropagation();
						this.answerWrong();
					}}
				>
					{strings.hotkeyButtonWrong}
				</button>
				<button
					onClick={(e) => {
						e.stopPropagation();
						this.answerUnanswered();
					}}
				>
					{strings.hotkeyButtonUnanswered}
				</button>
			</div>
		);
	};

	renderWaiting = () => {
		return (
			<div>
				<p>
					{strings.formatString(
						strings.testDone,
						strings.lk_testname
					)}
				</p>
				<button onClick={this.props.nextStage}>
					{strings.nextTest}
				</button>
			</div>
		);
	};
}

export default LetterKnowledgeAdmin;
