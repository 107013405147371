import {
	INIT_AUTH,
	SET_AUTH_ERROR,
	SET_AUTH_IFRAME_URL,
	SET_AUTH,
	RESET_AUTH,
	SET_AUTH_TOKEN,
} from '../actions/auth';

import {updateObject} from '../utils/reducerUtils';

const defaultState = {
	error: '',
	iframeUrl: '',
	isInit: false,
	isAuthenticated: false,
	user: null,
	token: null,
};

export default function auth(state = defaultState, action) {
	switch (action.type) {
		case INIT_AUTH:
			return updateObject(state, {
				//isInit: true,
			});
		case SET_AUTH_ERROR:
			return updateObject(defaultState, {
				error: action.error,
				//isInit: false,
			});
		case SET_AUTH_IFRAME_URL:
			return updateObject(state, {
				iframeUrl: action.iframeUrl,
			});
		case SET_AUTH: {
			return updateObject(state, {
				isAuthenticated: action.isAuthenticated,
				user: action.user,
			});
		}
		case SET_AUTH_TOKEN: {
			return updateObject(state, {
				...defaultState,
				isAuthenticated: action.isAuthenticated,
				token: action.token,
			});
		}
		case RESET_AUTH:
			return defaultState;
		default:
			return state;
	}
}
