import React, {Component} from 'react';
import moment from 'moment';

import ellipsis from '../../assets/images/ellipsis.gif';
import pc from '../../assets/images/pc-icon.png';
import server from '../../assets/images/server-icon.png';
import laptop from '../../assets/images/laptop-icon.png';
import check from '../../assets/images/check.png';

import './styles.scss';

class ConnectionStatus extends Component {
	constructor(props) {
		super(props);

		this.state = {};
	}

	render() {
		const {lastServerPong, lastClientPong} = this.props;

		let serverConnection = false;
		let clientConnection = false;
		if (lastServerPong)
			serverConnection =
				Math.abs(lastServerPong.diff(moment(), 'second')) < 5;
		if (lastClientPong)
			clientConnection =
				Math.abs(lastClientPong.diff(moment(), 'second')) < 5;

		const serverConnectionSrc = serverConnection ? check : ellipsis;

		const clientConnectionSrc = clientConnection ? check : ellipsis;

		return (
			<div className="connectionStatus">
				<img src={pc} alt="PC" className="connectionStatus__device" />
				<img
					src={serverConnectionSrc}
					alt=""
					className="connectionStatus__icon"
				/>
				<img
					src={server}
					alt="SERVER"
					className="connectionStatus__device"
				/>
				<img
					src={clientConnectionSrc}
					alt=""
					className="connectionStatus__icon"
				/>
				<img
					src={laptop}
					alt="REMOTE"
					className="connectionStatus__device"
				/>
			</div>
		);
	}
}

export default ConnectionStatus;
