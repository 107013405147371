import React, {Component} from 'react';

import listen from '../../assets/listen.png';

import './styles.scss';

import {nn_test, no_test} from './assets';

import strings from '../../strings';

class Test extends Component {
	constructor(props) {
		super(props);

		switch (strings.getLanguage()) {
			case 'nn':
				this.assets = nn_test;
				break;
			case 'no':
			default:
				this.assets = no_test;
				break;
		}

		Object.values(this.assets).forEach((audioEl) => audioEl.load());

		this.state = {
			currentStage: 0,
			lastPlayed: null,
		};
	}

	componentWillReceiveProps(newProps) {
		if (newProps.currentStep !== this.props.currentStep) {
			this.startNewTask(newProps.currentStep);
		}
	}

	componentDidMount() {
		if (this.button) this.button.click();
		this.initializeAudio();
	}
	componentWillUnmount() {
		this.stopSounds();
		clearTimeout(this.numbersTimer);
	}

	initializeAudio = () => {
		Object.values(this.assets).forEach((audioEl) => audioEl.load());

		this.timeout = setTimeout(
			() => this.startNewTask(this.props.currentStep),
			1000
		);
	};

	startNewTask = (taskNumber) => {
		this.stopSounds();
		if (this.state.lastPlayed !== taskNumber) {
			this.playSound(taskNumber);
			this.setState({
				lastPlayed: taskNumber,
			});
		}
	};

	playSound = (sound) => {
		this.assets[sound].play();
		this.assets[sound].onended = () => {
			//When sound ends, record current time
			this.props.startTimer();

			this.setState({
				startTime: new Date(),
			});
		};
	};

	stopSounds = () => {
		for (const sound of Object.values(this.assets)) {
			sound.pause();
		}
	};

	render() {
		return (
			<div className="WorkingMemory">
				<img className="listen-image" src={listen} alt="" />
				<p>{strings.pd_testInstruction}</p>
			</div>
		);
	}
}

export default Test;
