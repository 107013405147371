import React, {Component} from 'react';

import Intro from './Intro';
import Test from './Test';
import Pause from '../../components/Pause';
import strings from '../../strings';

const subStages = [Intro, Test, Pause];

class Spoonerism extends Component {
	constructor(props) {
		super(props);

		this.state = {
			currentStage: 0,
			currentStep: 0,
		};
	}

	componentWillReceiveProps(newProps) {
		const newState = {};
		let flag = false;
		if (newProps.currentStep !== this.props.currentStep) {
			newState.currentStep = newProps.currentStep;
			flag = true;
		}
		if (newProps.currentStage !== this.props.currentStage) {
			newState.currentStage = newProps.currentStage;
			flag = true;
		}
		if (flag) {
			this.setState(newState);
		}
	}

	render() {
		const {currentStage} = this.state;

		const CurrentSubStage = subStages[currentStage];

		return (
			<div className="Spoonerism">
				<h1 className="lit-page-title">{strings.sp_testname}</h1>
				<CurrentSubStage
					currentStep={this.state.currentStep}
					nextStep={this.props.nextStep}
					setReady={this.props.setReady}
					startTimer={this.props.startTimer}
					endTimer={this.props.endTimer}
					stepData={this.props.stepData}
					testName={strings.sp_testname}
					pauseSound={'test'}
				/>
			</div>
		);
	}
}

export default Spoonerism;
