import React, {Component} from 'react';
import strings from '../../strings';

import './styles.scss';

class AdminReadyButton extends Component {
	constructor(props) {
		super(props);

		this.state = {};
	}

	render() {
		const {remoteIsReady, isPractiseTasks} = this.props;

		const buttonText = isPractiseTasks
			? strings.startPracticeTasks
			: strings.starttest;

		return (
			<div className="AdminReadyButton">
				<button
					className="AdminReadyButton__button"
					disabled={!remoteIsReady}
					onClick={this.props.onClick}
				>
					{buttonText}
				</button>{' '}
				<p className="AdminReadyButton__label">
					{!remoteIsReady && strings.waitingForCandidate}
				</p>
			</div>
		);
	}
}

export default AdminReadyButton;
